::ng-deep *,
::ng-deep *::before,
::ng-deep *::after {
	box-sizing: border-box;
}

* {
	font: inherit;
}

body,
html {
	margin: 0px;
	padding: 0px;
	background-color: #f2f7ff;
	font-size: 16px;
}

body {
	margin: 0;
}

.p-button {
	margin: 0;
	padding: 0.5rem 1rem;
}

// Spinner

.progress-spinner {
	position: fixed;
	z-index: 999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.53);
}
